<template>
    <header class="font-Poppins">
        <nav id="nav" :class="{ sticky: this.active, nonSticky: !this.active }"
            class="py-3 h-24 transition flex items-center duration-500">
            <nav id="nav-container" class="relative container flex items-center" :class="toggleNavClass()">
                <img src="../assets/imgs/logo-epiwatch-original-orange-name.png" alt="" @click="scrollTo('main')"
                    class="cursor-pointer absolute left-0 ml-8 w-48 md:w-56 h-auto transition duration-500"
                    :class="{ transparent: this.active }" />

                <img src="../assets/imgs/logo-epiwatch-original-name.png" alt="" @click="scrollTo('main')"
                    class="cursor-pointer absolute left-0 ml-8 w-48 md:w-56 h-auto transition duration-500"
                    :class="{ transparent: !this.active }" />
                <!-- <transition name="navlogo" mode="out-in"> -->

                <!-- </transition> -->
                <ul class="hidden md:flex flex-1 justify-end items-center gap-12 text-bookmark-blue uppercase text-sm mr-4">
                    <li class="cursor-pointer hover:text-epiwatch-orange transition duration-300"
                        @click="scrollTo('features')">Features</li>
                    <li class="cursor-pointer hover:text-epiwatch-orange transition duration-300" @click="scrollTo('faq')">
                        FAQ</li>
                    <li class="cursor-pointer hover:text-epiwatch-orange transition duration-300" @click="scrollTo('team')">
                        Team</li>
                    <li class="cursor-pointer hover:text-epiwatch-orange transition duration-300"
                        @click="scrollTo('contact')">Contact</li>
                    <!-- <button type=" button" class="bg-bookmark-red text-white rounded-md px-7 py-3 uppercase">
                        Login</button> -->
                </ul>
                <div @click="toggleHamburger" class="flex md:hidden flex-1 justify-end">
                    <i class="text-3xl fa-solid fa-bars transition duration-500"
                        :class="{ rotate: this.hamburgerPressed }"></i>
                </div>
            </nav>
        </nav>

        <transition name="hamburger">


            <ul id="hamburger-list"
                class="flex flex-col items-center justify-center fixed bg-white top-0 left-0 z-50 w-full h-full gap-16"
                v-if="this.hamburgerPressed">
                <li class="cursor-pointer text-2xl uppercase font-medium" @click="scrollToHamburger('features')">
                    Features
                </li>
                <li class=" cursor-pointer text-2xl uppercase font-medium" @click="scrollToHamburger('faq')"> FAQ</li>
                <li class=" cursor-pointer text-2xl uppercase font-medium" @click="scrollToHamburger('team')"> Team</li>
                <li class=" cursor-pointer text-2xl uppercase font-medium" @click="scrollToHamburger('contact')">
                    Contact
                </li>
            </ul>
        </transition>
    </header>
</template >
    
<script>


export default {
    emits: ['scroll-comp-id'],
    data() {
        return {
            active: false,
            hamburgerPressed: false,
            lastLocationHamburger: 0
        }
    },
    methods: {
        scrollToHamburger(el) {
            this.toggleHamburger()
            this.scrollTo(el)
        },
        toggleHamburger() {
            if (!this.hamburgerPressed) {
                this.active = false
                document.body.style.overflow = "hidden";
            } else {
                this.active = true
                document.body.style.overflow = "visible";
            }
            this.hamburgerPressed = !this.hamburgerPressed;

        },
        toggleNavClass() {
            if (this.active == false) {
                return 'nav'
            } else {
                return 'sticky-nav'
            }
        },
        scrollTo(el) {
            if (el === 'main' && this.hamburgerPressed) {
                this.scrollToHamburger(el)
            }

            const yOffset = -90;
            const element = document.getElementById(el);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            // scrollIntoView(document.querySelector("#target"), { behavior: "smooth", block: "center", inline: "center" });


            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    },
    mounted() {
        console.log(" Here"); window.document.onscroll = () => {
            let navBar = document.getElementById('nav');
            if (window.scrollY > navBar.offsetTop && !this.hamburgerPressed) {
                this.active = true
                console.log("This is happening");
            } else {
                this.active = false
            }
        }
    }
}
</script>

<style scoped>
.rotate {
    transform: rotate(180deg)
}

.hamburger-enter-from,
.hamburger-leave-to {
    transform: translateY(-3rem);
    opacity: 0
}

.hamburger-enter-active,
.hamburger-leave-active {
    transition: all 500ms ease-in-out
}

.hamburger-leave-from,
.hamburger-enter-to {
    transform: translateY(0);
    opacity: 1
}

.transparent {
    opacity: 0;
}

.navlogo-enter-from {
    opacity: 0;
}

.navlogo-enter-active {
    transition: all 10ms ease-in;
}

.navlogo-enter-to {
    opacity: 1;
}

.navlogo-leave-from {
    opacity: 1;
}

.navlogo-leave-active {
    transition: all 10ms ease-in;
}

.navlogo-leave-to {
    opacity: 0;
}

.nav {
    /* transition: 100ms; */
}

.sticky-nav {
    /* transition: 100ms; */
}



#nav {
    /* display: flex;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    justify-content: space-between; */
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;
}

/* have to add the ID nav (#nav) otherwise the backgrnd color won't change as the previous background color is set in an ID and ID trumps class notation */
#nav.sticky {
    /* transition: 300ms; */
    box-shadow: 0px 15px 20px -15px rgb(59, 59, 59);
}

#nav.nonSticky {
    /* transition: 300ms; */
}
</style>
