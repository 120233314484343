<template>
    <form @submit.prevent="submitForm" class=" flex flex-col flex-wrap gap-8 mb-8" ref="form">
        <div class="flex flex-row flex-wrap gap-3">
            <div class="flex flex-col flex-1 items-start">
                <label for="firstname" class="w-full text-left font-light text-xs uppercase mb-2">Firstname*</label>
                <input type=" text" id="firstname" v-model="firstName" placeholder="Enter your first name "
                    class="w-full text-epiwatch-dark-blue px-2 py-3 rounded-md outline-none"
                    :class="{ form__error: !this.firstNameValid }">
            </div>
            <div class="flex flex-col flex-1 items-start">
                <label for="lastname" class="w-full text-left font-light text-xs uppercase mb-2">Lastname*</label>
                <input type=" text" id="lastName" v-model="lastName" placeholder="Enter your last name"
                    class="w-full text-epiwatch-dark-blue px-2 py-3 rounded-md  outline-none"
                    :class="{ form__error: !this.lastNameValid }">
            </div>
        </div>
        <div class="flex flex-col flex-1 items-start">
            <label for="email" class="w-full text-left font-light text-xs uppercase mb-2">Email*</label>
            <input type=" email" id="email" v-model="email" placeholder="Enter your email address"
                class="w-full text-epiwatch-dark-blue px-2 py-3 rounded-md outline-none"
                :class="{ form__error: !this.emailValid }">
        </div>
        <div class="flex flex-col flex-1 items-start">
            <label for="description" class="w-full text-left font-light text-xs uppercase mb-2">How can we help?</label>
            <textarea name="description" id="description" v-model="description" rows=10
                placeholder="Please let us know how we can help you"
                class="w-full text-epiwatch-dark-blue px-2 py-3 rounded-md  outline-none"></textarea>
        </div>
        <button type="submit" class="btn btn-purple hover:bg-bookmark-white hover:text-black"
            :class="{ form__button__error: !this.formValid }" ref="formSubmit">Contact Us</button>
    </form>
</template>

<script>

export default {
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            description: "",
            firstNameValid: false,
            lastNameValid: false,
            emailValid: false,
            isMounted: false
        }
    },
    mounted() {
        this.isMounted = true;
    },
    watch: {
        firstName(newValue) {
            this.validateFirstName(newValue);
            console.log(newValue);
        },
        lastName(newValue) {
            this.validateLastName(newValue)
        },
        email(newValue) {
            this.validateEmail(newValue)
        },
    },
    computed: {
        formValid() {
            let formIsValid = this.firstNameValid && this.lastNameValid && this.emailValid;

            if (formIsValid && this.isMounted) {
                this.$refs.formSubmit.innerText = "Contact Us"
            } else if (this.isMounted) {
                this.$refs.formSubmit.innerText = "Please Correct Errors"
            }

            return formIsValid
        }
    },
    emits: ['formSubmit'],
    methods: {
        validateFirstName(newValue) {
            if (newValue.length > 0) {
                this.firstNameValid = true;
            } else {
                this.firstNameValid = false;
            }
        },
        validateLastName(newValue) {
            if (newValue.length > 0) {
                this.lastNameValid = true;
            } else {
                this.lastNameValid = false;
            }
        },
        validateEmail(newValue) {
            let regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

            if (regex.test(newValue)) {
                this.emailValid = true;
            } else {
                this.emailValid = false;
            }
        },
        clearData() {
            this.firstName = '',
                this.lastName = '',
                this.email = '',
                this.description = ''
        },
        submitForm() {
            let formData = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                description: this.description
            }

            let that = this;
            async function putForm(formData) {

                const response = await fetch(
                    'https://epiwatch-site-default-rtdb.firebaseio.com/contact-requests.json',
                    {
                        method: 'POST',
                        body: JSON.stringify(formData),
                    }
                );

                console.log(response.ok);

                if (!response.ok) {

                    let form = that.$refs.form
                    form.style.maxHeight = "0";
                    form.style.overflow = "hidden";

                    that.$emit('formSubmit', false)

                    return
                }

                const responseData = await response.json();



                let form = that.$refs.form

                console.log(form)

                form.style.maxHeight = "0";
                form.style.overflow = "hidden";

                that.$emit('formSubmit', true)
            }

            putForm(formData)
        }
    }
}
</script>

<style scoped>
@keyframes bg-success {
    0% {
        background: #d71e1e;
    }

    25% {
        background: #00e676;
    }

    100% {
        background: lightgray;
    }
}

@keyframes bg-failure {
    0% {
        background-color: #d71e1e;
    }

    25% {
        background-color: #570000;
    }

    100% {
        background-color: lightgray;
    }
}

.submit__success {
    background-color: #00e676;
    /* animation: bg-success 5s ease-in; */
}

.submit__failure {
    animation: bg-failure 3s ease-in;
}

.form__error {
    background-color: #ffd1c5;
    outline: 2px solid #d7431e;
    outline-offset: 0px;
}

.label__error {
    color: #d7431e;
}

.form__button__error {
    pointer-events: none;
    background-color: lightgray;
}

.form__button__accept {
    background-color: #d7431e;
}
</style>