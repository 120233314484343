<template>
    <footer class="bg-epiwatch-darkest-blue pb-8 pt-12 font-Poppins">
        <div class="container flex flex-col sm:flex-row flex-wrap pb-8 border-b-2 border-white">
            <div class="flex flex-col grow-1">
                <img src="../assets/imgs/logo-epiwatch-white-name.png" alt="" class="w-40 h-auto mb-5" />
                <p class="text-left text-xs text-white font-light w-40">EpiWatch is a digital epilepsy platform, with
                    the
                    mission of
                    using digital
                    tools to
                    reduce the burden
                    of epilepsy</p>
            </div>
            <div class="flex flex-row flex-wrap grow-2 gap-10 justify-start sm:justify-end mt-8 sm:mt-0">
                <div class="flex flex-col ">
                    <p class=" text-left text-xs text-white font-light font-semibold mb-3">Sitemap</p>
                    <p class="cursor-pointer text-left text-xs text-white font-light" @click="scrollTo('features')">
                        Features</p>
                    <p class="cursor-pointer text-left text-xs text-white font-light" @click="scrollTo('faq')"> FAQ</p>
                    <p class=" cursor-pointer text-left text-xs text-white font-light" @click="scrollTo('team')"> Team
                    </p>
                    <p class=" cursor-pointer text-left text-xs text-white font-light" @click="scrollTo('contact')">
                        Contact</p>
                </div>
                <div class=" flex flex-col ">
                    <p class=" text-left text-xs text-white font-light font-semibold mb-3">Regulatory</p>
                    <p class="cursor-pointer text-left text-xs text-white font-light" @click="togglePrivacyPolicy">
                        Privacy Policy</p>
                    <!-- <p class="text-left text-xs text-white font-light">FDA</p> -->
                </div>
                <div class="flex flex-col ">
                    <p class="text-left text-xs text-white font-light font-semibold mb-3">Socials</p>
                    <p class="text-left text-xs text-white font-light"><a
                            href="https://www.linkedin.com/company/epiwatch-inc/">LinkedIn</a></p>
                    <!-- <p class="text-left text-xs text-white font-light">Twitter</p>
                    <p class="text-left text-xs text-white font-light">Instagram</p>
                    <p class="text-left text-xs text-white font-light">YouTube</p> -->
                </div>
                <div class="flex flex-col ">
                    <p class="text-left text-xs text-white font-light font-semibold mb-3">Get In Touch</p>
                    <p class="text-left text-xs text-white font-light">1812 Ashland Avenue</p>
                    <p class="text-left text-xs text-white font-light">Suite 110</p>
                    <p class="text-left text-xs text-white font-light">Baltimore</p>
                    <p class="text-left text-xs text-white font-light mb-2">Maryland, 21210</p>
                    <!-- <p class="text-left text-xs text-white font-light"><a
                            href="mailto:help@epiwatch.com">help@epiwatch.com</a></p> -->
                </div>
            </div>

            <!-- <div class="flex flex-1 flex-wrap items-center justify-between gap-x-12 gap-y-6">
                <img src="../assets/imgs/logo-epiwatch-white-name.png" alt="" class="w-28" />
                <ul class="list-none flex text-white uppercase gap-12 text-xs">
                    <li class="cursor-pointer">Features</li>
                    <li class="cursor-pointer">Team</li>
                    <li class="cursor-pointer">Contact</li>
                </ul>
                <div>
                    <ul class="flex gap-3">
                        <li><i class="text-white text-2xl fa-brands fa-twitter"></i></li>
                        <li><i class="text-white text-2xl fa-brands fa-facebook-square"></i></li>
                    </ul>
                </div>
            </div> -->
        </div>
        <p class="text-white text-xs mt-5">EpiWatch, Inc. Copyright 2023</p>
    </footer>

    <transition name="privacy">
        <div class="fixed top-0 left-0 w-full h-full bg-white z-top overflow-scroll font-Poppins text-sm text-left px-10 md:px-56 py-20"
            v-if="this.privacyPolicyClicked">
            <div class="cursor-pointer absolute top-0 right-0 py-8 px-10" @click="this.togglePrivacyPolicy">
                <i class="text-3xl fa-solid fa-xmark"></i>
            </div>
            <h2 class="text-epiwatch-dark-blue text-3xl md:text-4 lg:text-5xl text-center lg:text-left mb-6 font-medium">
                EpiWatch Privacy Policy<span class="text-epiwatch-orange">.</span>
            </h2>

            <p>This
                Privacy Policy applies to EpiWatch, Inc. (“EpiWatch”) operated websites
                (collectively the “<b>EpiWatch Applications</b>”). This Privacy Policy
                also applies to the EpiWatch provided content on any social media sites such as
                Facebook, LinkedIn, Twitter and similar platforms, however, those social media
                platforms have their own data collection and use policies, and EpiWatch is not
                responsible for such policies, or how such sites may use information you
                provide on those sites (including information provided to EpiWatch through
                those sites), or how such sites track or monitor your usage of those services.</p>
            <br>
            <p>By
                using the EpiWatch Applications, you consent to the collection and use of your
                Personal Data by EpiWatch as set forth in this Privacy Policy.</p>
            <br>
            <p>EpiWatch
                is committed to transparency about its data collection and data use policies,
                which are described in this Privacy Policy, so that you can make an informed
                decision as to whether to use EpiWatch Applications.</p>

            <br>
            <p>
                EpiWatch Applications collect the following information:
            </p>

            <br>
            <p>
                If you complete the contact information form and submit it, we collect your
                name, email address and if you include a comment or text, the text you
                submit. DO NOT submit sensitive Personal Data using this form – for
                example, do not submit your social security number, medical record number, bank
                account information, or other sensitive information.
            </p>

            <br>
            <p>
                Website: In
                this Privacy Policy, (a) all information that identifies you as an individual
                (but not anonymized and/or deidentified data), (b) if you are a resident of a
                country that is subject to such regulation, that is within the definition of
                Personal Data as that term is defined under the General Data Protection
                Regulation effective 25 May 2018 (“GDPR”) in the European Union, and (c) to the
                extent such information is defined as Personal Data under an applicable state’s
                law and/or implementing regulations, such as the California Consumer Privacy
                Act of 2018, is collectively referred to as “<b>Personal Data</b>”.
            </p>

            <br>
            <p>
                <b>GENERAL
                    POLICY:</b> EpiWatch does not access or use your Personal Data for
                any purpose other than to provide the functionality described in the EpiWatch
                Applications. EpiWatch does not disclose or further transfer your Personal Data
                other than (a) at your direction when (and if) you decide to share your
                Personal Data with another person or application; and (b) to comply with law,
                such as a subpoena, or in the event that EpiWatch’s business is transferred to
                a successor through a merger, sale, or other similar sale of business
                transaction (a “<b>Change of Control</b>”). EpiWatch does not sell your
                Personal Data.
            </p>
            <br>
            <p>
                <b>CHANGES:</b> EpiWatch reserves the right to change this Privacy Policy from time to
                time. If EpiWatch does change this Privacy Policy, such changes will be
                posted or otherwise noticed in the EpiWatch Applications, and will be effective
                if you continue to use the EpiWatch Applications after such notice. If
                you do not agree with the changes to the Privacy Policy you may cease using the
                EpiWatch Applications and such revised terms shall not apply to
                you.
            </p>
            <br>
            <p><b>DETAILED POLICY</b></p>
            <br>
            <p><b>Use
                    of Contact information</b></p>
            <p> EpiWatch may use your email
                address or other contact information, and other information such as personal or
                professional interests, demographics, experience with our programs, and more
                detailed contact preferences (“<b>Contact Information</b>”) to send you communications.
                EpiWatch will not provide your Contact Information to third parties (other than
                EpiWatch Service Providers, solely to provide the EpiWatch services to you, as
                required by applicable law, or in a Change of Control) without your express
                consent. You may instruct EpiWatch to stop communicating with you, and
                EpiWatch will honor such requests made in accordance with the procedures
                available in the EpiWatch Applications. However, be aware that EpiWatch
                may still send you transactional information regarding EpiWatch Application
                issues, such as notices of changes in this Privacy Policy, updates to the
                EpiWatch Applications and similar relationship communications.</p>

            <br>
            <p><b>Automatically
                    collected information. </b>If you use the EpiWatch website, it may use cookie
                technology which stores small files on your computer, to assist with session
                management and preserving your login context on the website. In addition,
                the EpiWatch website may automatically collect certain information regarding
                the communications between your browser/computer and the EpiWatch servers, such
                as referring source, domain name, internet protocol address, the type of web
                browser, and other data that is automatically transferred between computers in
                a typical internet session (“<b>Automatically Collected Data</b>”).
                Automatically Collected Data is used by EpiWatch only for its internal purposes
                but is subject to disclosure to the extent required by applicable law, or in a
                Change of Control. You also have choices with respect to limiting some
                Automatically Collected Data. For example, by modifying your browser
                preferences, or the settings on your electronic device that you use to access
                the EpiWatch Applications, you may set an option to accept or reject cookies or
                to be notified when a cookie is set. Some of these setting may interfere with
                or prevent the operation of the EpiWatch Applications. Accepting a cookie
                in no way gives EpiWatch access to your computer or any Personal Data about
                you, other than the data you chose to share with EpiWatch through the
                cookie.</p>

            <br>

            <p><b>Analytics.</b> As part of its Automatically Collected Data, the EpiWatch website may use third
                party website analytics providers, such as Google Analytics, to track certain
                automated data created by your use of the website. EpiWatch does not
                control Google’s use of this Automatically Collected Data, which is subject to
                the applicable provider’s privacy policy (in the case of Google, its privacy
                policy is located here: https://policies.google.com/privacy, and a
                general description of the Google Analytics tool and its data collection and
                use policies is located here:
                https://support.google.com/analytics/answer/6004245). EpiWatch only uses
                website analytics data internally to improve the EpiWatch website, and for no
                other purpose.</p>

            <br>
            <p><b>Anonymized/aggregated
                    data.</b> If EpiWatch de-identifies any data or information
                (including any Personal Data), or the information is itself already anonymous
                (i.e. most Automatically Collected Data), such that such data does not link to
                or identify you, then such data is anonymous data, and EpiWatch owns this
                anonymous data and EpiWatch may use such data for any purpose and disclose such
                data to any third-party and EpiWatch has no duty to you in respect of such
                anonymous data, except solely to the extent provided under applicable law
                applicable to EpiWatch.</p>

            <br>
            <p><b>Data
                    location.</b> All Personal Data, Contact Information, Automatically
                Collected Data is stored in the United States, and United States laws may be
                different than the laws of your country of residence. You consent to our
                storage in the United States of any data or Personal Data you send us.</p>

            <br>
            <p><b>Who
                    is Collecting and has access to Personal Data?</b></p>

            <br>
            <p>Only
                EpiWatch and its Service Providers collect or have access to Personal Data that
                is obtained when using the EpiWatch Applications. EpiWatch does not
                control what data is collected by, or how that data is used by, third party
                applications or platforms such as Apple, Google, Facebook, LinkedIn, Twitter
                and similar third-party sites and applications, and EpiWatch is not responsible
                or liable for any collection, misuse, breach or other action or inaction by
                such third parties.</p>
            <br>
            <p>In
                addition, EpiWatch will comply with legal requests such as subpoenas, court
                orders and law enforcement requests to disclose information EpiWatch may have
                in its possession, and EpiWatch may transfer information, including your
                Personal Data, in a Change of Control, however, it will be transferred subject
                to this Privacy Policy.</p>
            <br>
            <p><b>How
                    Does EpiWatch Use the Information We Collect?</b></p>
            <br>
            <p>EpiWatch
                uses your Personal Data solely to provide EpiWatch services in the EpiWatch
                Applications to you.</p>

            <br>
            <p><b>Who
                    Can We Share the Personal Data With?</b></p>
            <br>
            <p>Except
                as outlined in this Privacy Policy or specifically on the EpiWatch Applications
                at a point where you affirmatively opt in, EpiWatch will not sell, rent, lease,
                or share your Personal Data to or with others without your consent.</p>
            <br>
            <p>EpiWatch
                may also disclose and use Personal Data in special cases when we have reason to
                believe that disclosing this information is necessary to identify, contact or
                bring legal action against someone who may be violating EpiWatch’s rights or
                agreements or causing injury to or interference with EpiWatch’s or other’s
                property or the EpiWatch Applications. EpiWatch may also disclose or access
                Personal Data when we believe in good faith that the law requires it.
                EpiWatch may also access or disclose Personal Data to respond to an emergency.</p>
            <br>
            <p>The
                EpiWatch Applications may provide you with the ability to send some of your
                Personal Data to a third party, such as a healthcare provider. Please be
                aware that this Personal Data is NOT encrypted, and it is not protected health
                information under HIPAA. If you (or someone with your login) invokes this
                feature, EpiWatch will send the Personal Data you (or the person accessing your
                account with your credentials) designate, to the person you designate.;
                EpiWatch is not liable or responsible for use or misuse of Personal Data that
                exits the EpiWatch Applications via this feature.</p>

            <br>
            <p><b>What
                    Choices Do I Have Regarding Collection, Use and Distribution of My Information?</b></p>
            <br>
            <p>EpiWatch
                may provide some ability to limit what Personal Data is collected from you, however,
                except as specifically required by applicable law, EpiWatch has no obligation
                to provide such options or choice. If you ever have questions regarding
                any communications you receive from EpiWatch or its vendors or about data
                collection and use policies, please contact EpiWatch by email at <a
                    href="mailto:privacy@epiwatch.com">privacy@epiwatch.com</a></p>

            <br>
            <p><b>What
                    Security Measures are Used by EpiWatch?</b></p>
            <br>
            <p>EpiWatch
                will use commercially reasonable efforts to protect the Personal Data you share
                with EpiWatch in light of the nature and extent of such Personal Data, but in
                any event shall comply with applicable law.</p>

            <br>
            <p><b>Compliance
                    with law</b></p>
            <br>
            <p>EpiWatch
                will comply with law applicable to EpiWatch. For example, if applicable
                law requires EpiWatch to offer you the right to have your Personal Data deleted
                or corrected, or to obtain an accounting of disclosures of such Personal Data,
                then to the extent EpiWatch has such Personal Data, EpiWatch will comply with
                its legal obligations. Also see the addendum regarding GDPR which is
                incorporated herein by reference. EpiWatch may also choose to voluntarily
                enable such rights, even if not required to do so, but in such case, this is
                optional to EpiWatch. You may make any requests to EpiWatch at <a
                    href="mailto:privacy@epiwatch.com">privacy@epiwatch.com</a></p>

            <br>
            <p><b>Notice
                    concerning Communications Decency Act?</b></p>
            <br>
            <p>EpiWatch
                hereby notifies You that parental control protections (such as computer
                hardware, software, or filtering services) are commercially available that may
                assist You in limiting access to material that is not suitable to minors. You
                can discover some of the providers of such parental control technology by
                searching for "parental control software" using a major search
                engine, or by visiting https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online.
            </p>

            <br>
            <p><b>What
                    If I Have Additional Questions or Comments?</b></p>
            <br>
            <p>Questions
                regarding this policy should be directed to EpiWatch by email at <a
                    href="mailto:privacy@epiwatch.com">privacy@epiwatch.com</a></p>

            <br>
            <p><b>CCPA
                    NOTICE AT COLLECTION / DATA COLLECTION CONSENT</b></p>
            <br>
            <p>This
                portion of our Privacy Policy is a notice to you concerning the collection and
                use of Personal Data about you collected and used by Us, that is required by
                certain laws.</p>
            <br>
            <p><b>Categories
                    of Personal Data we collect and the purpose for which we collect such
                    data: </b>The categories of Personal Data we collect and examples are as
                follows (if not listed, we do not collect any other categories of information
                from you, for example, we do not collect biometric information from you):</p>

            <br>
            <table>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Examples (not exhaustive)</th>
                        <th>Purpose for which we collect and use this information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Personal identifiers</td>
                        <td>Name, address, phone numbers, account user names, internet protocol address, email address,
                            social media account addresses&nbsp;</td>
                        <td>To communicate with you, and verify your identity when using out service&nbsp;</td>
                    </tr>
                    <tr>
                        <td>Data provided by others</td>
                        <td>Our service may be configured to enable other users and our customers to proved EpiWatch
                            with information about you&nbsp;</td>
                        <td>To correct information in our database</td>
                    </tr>
                    <tr>
                        <td>Geo-location data&nbsp;</td>
                        <td>We may perform a geo-location lookup when you are using our service</td>
                        <td>To determine where the data is coming from, and for security/identity purposes to protect
                            our systems (note 1)</td>
                    </tr>
                    <tr>
                        <td>Device information&nbsp;</td>
                        <td>Browser software used, device type (i.e. computer, mobile), device identifiers</td>
                        <td>&nbsp;To monitor and improve our systems and user experience</td>
                    </tr>
                </tbody>
            </table>

            <br>
            <p>(note
                1): We do not store geolocation data associated with you and we discard it
                after it is used, however our log files may contain some of this information,
                which is only used to diagnose a security incident or to verify our compliance
                with data transfer or other legal compliance obligations.</p>

            <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black mt-10 mx-auto"
                @click="togglePrivacyPolicy">Exit</button>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            privacyPolicyClicked: false
        }
    },
    methods: {
        togglePrivacyPolicy() {
            this.privacyPolicyClicked = !this.privacyPolicyClicked
            document.body.style.overflow = this.privacyPolicyClicked ? "hidden" : "visible"
        },
        scrollTo(el) {
            const yOffset = -90;
            const element = document.getElementById(el);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        },
    }
}
</script>

<style scoped>
.privacy-enter-from,
.privacy-leave-to {
    transform: translateY(-3rem);
    opacity: 0
}

.privacy-enter-active,
.privacy-leave-active {
    transition: all 500ms ease-in-out
}

.privacy-enter-to,
.privacy-leave-from {
    transform: translateY(0rem);
    opacity: 1
}

.z-top {
    z-index: 500;
}

table {
    border: 1px solid #b3adad;
    border-collapse: collapse;
    padding: 5px;
}

table th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #264e76;
    color: #ffffff;
}

table td {
    border: 1px solid #b3adad;
    text-align: left;
    padding: 5px;
    background: #ffffff;
    color: #313030;
}
</style>