<template>
  <section id="main" class="font-Poppins overflow-x-hidden">
    <!-- Hero -->
    <section class="relative ">
      <div
        class="absolute w-full h-full overflow-hidden bg-epiwatch-darkest-blue after:absolute after:inset-0
                                                                                                                                                  after:z-[0]
                                                                                                                                                  after:block
                                                                                                                                                  after:w-full after:h-full
                                                                                                                                                  after:bg-gradient-to-t after:from-white/60 after:to-white">
        <video autoplay muted loop id="myVideo"
          class="min-w-full min-h-full h-auto w-auto top-0 left-0 absolute object-cover">
          <source src="../assets/imgs/EpiWatchVideoCut.mp4" type="video/mp4">
        </video>
      </div>
      <div class="container flex flex-col-reverse lg:flex-row items-center gap-12 pt-36 lg:pt-52 pb-20 lg:pb-60 z-10">
        <!-- <div class="container flex flex-col-reverse lg:flex-row items-center gap-12 mt-36 lg:mt-52"></div> -->
        <!-- Content -->
        <div class="flex flex-1 flex-col lg:items-start z-10">
          <h2
            class="text-epiwatch-dark-blue text-4xl md:text-5xl lg:text-7xl text-center lg:text-left mb-6 font-medium z-10">
            Digital Health, <span class="text-epiwatch-orange ">for Epilepsy.</span>
          </h2>

          <p class="text-epiwatch-darkest-blue text-xl text-center lg:text-left mb-6">The future epilepsy management is
            digital.
            At EpiWatch,
            and we aim to make the best possible software to empower the entire epilepsy community to advance research,
            optimize care, and improve outcomes.</p>
          <div class="flex justify-center flex-wrap gap-6">
            <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black"
              @click="scrollTo('contact')">Join the
              Waitlist</button>
            <!-- <button type="button" class="btn btn-white hover:bg-epiwatch-dark-blue hover:text-white">Join
              Waitlist</button> -->
          </div>
        </div>
        <div class="flex justify-center flex-1 mb-10 md:mb-16 lg:mb-0 z-10">
          <img src="../assets/imgs/epiwatch_mockup.png" alt=""
            class="w-3/6 h-3/6 sm:w-3/6 sm:h-3/6 lg:h-5/6 lg:w-5/6 md:w-3/6 md:h-3/6">
        </div>
      </div>
      <!-- Rounded Rectangle -->
      <div
        class="hidden lg:block bg-epiwatch-dark-blue rounded-l-full absolute h-80 w-2/4 top-96 right-0 lg:-bottom-28 lg:-right-36">
      </div>
    </section>
    <!-- Features -->
    <section id="features" class="bg-bookmark-white py-20">
      <!-- Heading -->
      <div class="sm:w-3/4 lg:w-7/12 mx-auto px-2">
        <h1 class="text-3xl text-center text-epiwatch-dark-blue font-medium">What we do<span
            class="text-epiwatch-orange">.</span></h1>
        <p class="text-center text-bookmark-grey mt-4">EpiWatch® will lead the digital
          transformation of epilepsy care by delivering innovative and integrated services that keep people with
          epilepsy safe, promote epilepsy management, and provide peace of mind for loved ones.</p>
      </div>
      <!-- Feature 1 -->
      <div class="relative mt-20 lg:mt-24">
        <div class="container flex flex-col lg:flex-row items-center justify-center gap-x-24">
          <!-- Image -->
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <!-- <img src="../assets/imgs/apple-iphone-mac.png" alt=""
              class="w-3/6 h-3/6 sm:w-3/6 sm:h-3/6 md:w-3/6 md:h-3/6 lg:h-full lg:w-full"> -->
            <img src="../assets/imgs/feature-illustrations/medicine.svg" alt=""
              class="w-3/6 h-3/6 sm:w-3/6 sm:h-3/6 md:w-3/6 md:h-3/6 lg:h-full lg:w-full">
          </div>
          <!-- Content -->
          <div class="flex flex-1 flex-col items-center lg:items-start">
            <h1 class="text-3xl text-bookmark-blue font-medium text-center lg:text-left">All-in-one Disease
              Management<span class="text-epiwatch-orange">.</span>
            </h1>
            <p class="text-bookmark-grey my-4 text-center lg:text-left sm:w-3/4 lg:w-full">EpiWatch centralizes all your
              epilepsy-related data for easy access by your physician. Our comprehensive digital platform allows you to
              track medications, seizures, and triggers, enabling personalized and data-driven management plans to improve
              your overall care.</p>
            <button type="button" class=" btn btn-outline">
              Coming Soon
            </button>
          </div>
        </div>
        <!-- Rounded Rectangle -->
        <div class="hidden lg:block bg-epiwatch-dark-blue rounded-r-full absolute h-80 w-2/4 -bottom-24 -left-36">
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="relative mt-20 lg:mt-52">
        <div class="container flex flex-col lg:flex-row-reverse items-center justify-center gap-x-24">
          <!-- Image -->
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <!-- <img src="../assets/imgs/Apple-Watch-Single-2.png" alt=""
              class="w-2/6 h-2/6 sm:w-2/6 sm:h-2/6 lg:h-4/6 lg:w-4/6 md:w-3/6 md:h-3/6"> -->
            <img src="../assets/imgs/feature-illustrations/location-on-watch.svg" alt=""
              class="w-2/6 h-2/6 sm:w-2/6 sm:h-2/6 lg:h-4/6 lg:w-4/6 md:w-3/6 md:h-3/6">
          </div>
          <!-- Content -->
          <div class="flex flex-1 flex-col items-center lg:items-start">
            <h1 class="text-3xl text-bookmark-blue font-medium text-center lg:text-left">Advanced Seizure Detection<span
                class="text-epiwatch-orange">.</span>
            </h1>
            <p class="text-bookmark-grey my-4 text-center lg:text-left sm:w-3/4 lg:w-full">Experience peace of mind with
              our state-of-the-art Tonic Clonic Seizure detection algorithm. Running in real-time on your smartwatch,
              EpiWatch instantly alerts caregivers when a seizure occurs, ensuring help is always close by.</p>
            <button type="button" class="btn btn-outline hover:bg-bookmark-white hover:text-black">
              Coming Soon
            </button>
          </div>
        </div>
        <!-- Rounded Rectangle -->
        <div class="hidden lg:block bg-epiwatch-darkest-blue rounded-l-full absolute h-80 w-2/4 -bottom-24 -right-36">
        </div>
      </div>
      <!-- Feature 3 -->
      <div class="relative mt-20 lg:mt-52">
        <div class="container flex flex-col lg:flex-row items-center justify-center gap-x-24">
          <!-- Image -->
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <!-- <img src="../assets/imgs/macbook.png" alt=""
              class="w-3/6 h-3/6 sm:w-3/6 sm:h-3/6 md:w-3/6 md:h-3/6 lg:h-full lg:w-full"> -->
            <img src="../assets/imgs/feature-illustrations/team-analytics.svg" alt=""
              class="w-3/6 h-3/6 sm:w-3/6 sm:h-3/6 md:w-3/6 md:h-3/6 lg:h-full lg:w-full">
          </div>
          <!-- Content -->
          <div class="flex flex-1 flex-col items-center lg:items-start">
            <h1 class="text-3xl text-bookmark-blue font-medium text-center lg:text-left">AI-Driven Insights and
              Visualization.<span class="text-epiwatch-orange">.</span></h1>
            <p class=" text-bookmark-grey my-4 text-center lg:text-left sm:w-3/4 lg:w-full">Gain a deeper understanding of
              your epilepsy management strategy through our intuitive dashboard, complete with data charts and AI-based
              insights focused on seizure patterns and triggers, to continually enhance your care.</p>
            <button type="button" class="btn btn-outline hover:bg-bookmark-white hover:text-black">
              Coming Soon
            </button>
          </div>
        </div>
        <!-- Rounded Rectangle -->
        <div class="hidden lg:block bg-epiwatch-blue rounded-r-full absolute h-80 w-2/4 -bottom-24 -left-36">
        </div>
      </div>
    </section>

    <!-- Download -->
    <section class=" bg-epiwatch-dark-blue bg-cover bg-center">
      <div
        class="bg-gradient-to-t from-epiwatch-dark-blue/100 via-epiwatch-dark-blue/90 to-epiwatch-dark-blue/100 h-full w-full py-20 pt-20">
        <!-- Heading -->
        <div class="sm:w-3/4 lg:w-7/12 mx-auto px-2">
          <h1 class="text-3xl text-center text-epiwatch-orange font-medium">Empowering the Epilepsy Community<span
              class="text-epiwatch-blue">.</span>
          </h1>
          <p class="text-center text-bookmark-grey mt-4">EpiWatch is committed to supporting everyone within the epilepsy
            community.</p>
        </div>

        <!-- Cards -->
        <div class="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-16 max-w-screen-lg mt-16 ">
          <!-- HELP-3 -->
          <div
            class="flex flex-col rounded-md shadow-lg bg-slate-100/40 hover:shadow-2xl hover:bg-slate-100 transition hover:duration-300 hover:scale-110">
            <div class="p-6 flex flex-col items-center">
              <img src="../assets/imgs/person-1.png" alt=""
                class="w-3/6 h-3/6 sm:w-auto sm:h-40 md:w-auto md:h-40 lg:h-40 lg:w-auto">
              <h3 class="mt-5 mb-2 text-epiwatch-dark-blue text-xl font-medium">People With Epilepsy</h3>
              <p class="mb-2 text-epiwatch-dark-blue font-light">Simplify your epilepsy management with EpiWatch by
                receiving
                medication reminders, understanding your seizure triggers, screening for depression and anxiety, and
                effortlessly logging seizures.</p>
            </div>
            <!-- <hr class="border-b border-bookmark-white" /> -->
            <!-- <div class="flex p-6">
          <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black mx-auto">
            Learn More
          </button>
        </div> -->
          </div>
          <!-- HELP-4 -->
          <div
            class="flex flex-col rounded-md shadow-lg bg-slate-100/40 hover:shadow-2xl hover:bg-slate-100 transition hover:duration-300 hover:scale-110">
            <div class="p-6 flex flex-col items-center">
              <img src="../assets/imgs/nurse-female.png" alt=""
                class="w-3/6 h-3/6 sm:w-auto sm:h-40 md:w-auto md:h-40 lg:h-40 lg:w-auto">
              <h3 class="mt-5 mb-2 text-epiwatch-dark-blue text-xl font-medium">Caregivers</h3>
              <p class="mb-2 text-epiwatch-dark-blue font-light">Let EpiWatch's proprietary technology give you peace of
                mind
                by
                alerting you when the person you care for experiences a dangerous tonic-clonic seizure, reducing the risk
                of
                injury or SUDEP (sudden unexpected death in epilepsy).</p>
            </div>
            <!-- <hr class="border-b border-bookmark-white" /> -->
            <!-- <div class="flex p-6">
          <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black mx-auto">
            Learn More
          </button>
        </div> -->
          </div>
          <!-- HELP-1 -->
          <div
            class="flex flex-col rounded-md bg-slate-100/40 shadow-lg hover:shadow-2xl hover:bg-slate-100 transition hover:duration-300 hover:scale-110">
            <div class="p-6 flex flex-col items-center">
              <img src="../assets/imgs/doctor-female.png" alt=""
                class="w-3/6 h-3/6 sm:w-auto sm:h-40 md:w-auto md:h-40 lg:h-40 lg:w-auto">
              <h3 class="mt-5 mb-2 text-epiwatch-dark-blue text-xl font-medium">Physicians</h3>
              <p class="mb-2 text-epiwatch-dark-blue font-light">EpiWatch enables the remote collection of patient data,
                providing a comprehensive overview of each patient to aid in diagnosing seizure types, assessing SUDEP
                risk,
                and optimizing care plans..</p>
            </div>
            <!-- <hr class="border-b border-bookmark-white" /> -->
            <!-- <div class="flex p-6">
          <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black mx-auto">
            Learn More
          </button>
        </div> -->
          </div>
          <!-- HELP-2 -->
          <div
            class="flex flex-col bg-slate-100/40 rounded-md shadow-lg hover:shadow-2xl hover:bg-slate-100 transition hover:duration-300 hover:scale-110">
            <div class=" p-6 flex flex-col items-center">
              <img src="../assets/imgs/researcher.png" alt=""
                class="w-3/6 h-3/6 sm:w-auto sm:h-40 md:w-auto md:h-40 lg:h-40 lg:w-auto">
              <h3 class="mt-5 mb-2 text-epiwatch-dark-blue text-xl font-medium">Researchers and Institutions</h3>
              <p class="mb-2 text-epiwatch-dark-blue font-light">Utilize EpiWatch's consumer smartwatch-based data
                platform to
                gather and store physiological and patient-reported data from various environments, streamlining clinical
                trial processes.</p>
            </div>
            <!-- <hr class="border-b border-bookmark-white" /> -->
            <!-- <div class="flex p-6">
          <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black mx-auto">
            Learn More
          </button>
        </div> -->
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ -->
    <section id="faq" class="bg-bookmark-white py-20">
      <div class="container">
        <!-- Heading -->
        <div class="sm:w-3/4 lg:w-7/12 mx-auto px-2">
          <h1 class="text-3xl text-center text-epiwatch-dark-blue font-medium">Frequently Asked Questions<span
              class="text-epiwatch-orange">.</span>
          </h1>
          <p class="text-center text-bookmark-grey mt-4">Here are some of our most commonly asked FAQs. If you have any
            other questions you would like answered, please feel free to contact us.</p>
        </div>
        <!-- FAQ Items -->
        <div class="flex flex-col sm:w-3/4 lg:w-5/12 mt-12 mx-auto gap-2">
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">When will EpiWatch be available?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="px-4 pointer-events-none py-3 text-bookmark-grey">EpiWatch aims for a commercial release in 2023.
                Join our waitlist to receive updates on availability.</p>
            </div>
          </div>
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">Can I get early access?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="px-4 pointer-events-none py-3 text-bookmark-grey">We are currently providing early access to
                select individuals. Please contact us to learn more.</p>
            </div>
          </div>
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">What type of seizures does EpiWatch detect?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="px-4 pointer-events-none py-3 text-bookmark-grey">EpiWatch currently detects tonic-clonic
                seizures, with plans to expand to other seizure types in the future.</p>
            </div>
          </div>
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">Where can I get EpiWatch?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="pointer-events-none py-3 text-bookmark-grey">EpiWatch will initially be available in the United
                States, via the Apple App Store.</p>
            </div>
          </div>
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">What is the cost of EpiWatch?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="pointer-events-none py-3 text-bookmark-grey">EpiWatch is not currently available. Join our
                waitlist to stay informed about its release.</p>
            </div>
          </div>
          <div class="cursor-pointer rounded-lg text-epiwatch-dark-blue overflow-hidden " @click.stop="FAQToggle($event)">
            <div
              class="font-medium cursor-pointer pointer-events-none flex items-center border-b py-4 transition-all duration-300">
              <span class=" pointer-events-none flex-1 ">What hardware does EpiWatch require?</span>
              <i
                class="pointer-events-none fa-solid fa-chevron-down px-3 text-epiwatch-orange -rotate-90 transition-all duration-300"></i>
            </div>
            <div class="pointer-events-none max-h-0 bg-white text-sm transition-all duration-300">
              <p class="pointer-events-none py-3 text-bookmark-grey">EpiWatch requires an Apple Watch and a paired iPhone
                for Tonic Clonic Seizure detection.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Team -->
    <section id="team" class="py-20">
      <!-- Heading -->
      <div class="sm:w-3/4 lg:w-7/12 mx-auto px-2">
        <h1 class="text-3xl text-center text-epiwatch-dark-blue font-medium">Meet our Team<span
            class="text-epiwatch-orange">.</span>
        </h1>
        <p class="text-center text-bookmark-grey mt-4">The EpiWatch team is experienced, committed and passionate about
          improving epilepsy care.</p>
      </div>
      <ul class=" container flex flex-wrap max-w-screen-lg mt-16 gap-6 justify-center ">
        <li
          class=" flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/dnp.png" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Dean Papadopoulos</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">MBA</p>
          <p class="text-base text-epiwatch-orange font-normal ">CEO</p>

        </li>

        <li
          class=" flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/ss2.png" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Samyak Shah</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">MSE</p>
          <p class="text-base text-epiwatch-orange font-normal ">Head of Technology</p>

        </li>

        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/gk.png" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Gregory L. Krauss</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">M.D.</p>
          <p class="text-base text-epiwatch-orange font-normal ">Scientific Board</p>

        </li>
        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/nc.png" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Nathan E. Crone</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">M.D.</p>
          <p class="text-base text-epiwatch-orange font-normal ">Scientific Board</p>

        </li>
        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/ms.png" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Michael Singer</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">PhD</p>
          <p class="text-base text-epiwatch-orange font-normal ">Executive Chairman</p>

        </li>
        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/dh.jpg" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Dan F. Hanley Jr.</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">M.D.</p>
          <p class="text-base text-epiwatch-orange font-normal ">Board of Directors</p>

        </li>
        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/rt.jpg" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Richard W. Thomas</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">M.D., DDS, FACS</p>
          <p class="text-base text-epiwatch-orange font-normal ">Board of Directors</p>

        </li>
        <li
          class="flex flex-col grow-0 shrink-0 w-56 h-80 rounded-3xl overflow-hidden shadow-lg grayscale hover:grayscale-0 hover:shadow-xl hover:scale-105 transition duration-100 items-center justify-start gap-1">
          <div class="w-56 h-56"><img src="../assets/imgs/team/ds.jpg" alt="" class="w-full h-full object-cover">
          </div>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">Richard C. Schmitt</p>
          <p class="text-sm text-epiwatch-dark-blue font-semibold">MBA</p>
          <p class="text-base text-epiwatch-orange font-normal ">Board of Directors</p>

        </li>
      </ul>
    </section>

    <section id="contact" class="bg-epiwatch-dark-blue text-white py-20 transition-all duration-2000"
      :style="this.formSubmitClass">
      <div class="container" :class="{ form__submitted: this.formSubmitted }">
        <div class="sm:w-3/4 lg:w-2/4 mx-auto">

          <p class="font-light uppercase text-center mb-8 text-epiwatch-orange">
            35,000+ ALREADY JOINED
          </p>
          <h1 class="text-3xl text-center mb-8">Stay up-to-date with what we're doing</h1>

          <ContactForm @form-submit="formSubmitCallback"></ContactForm>
          <!-- <div class="flex flex-col sm:flex-row gap-6 mt-8">
            <input type="text" placeholder="Enter your email address"
              class="focus:outline-none text-epiwatch-orange flex-1 px-2 py-3 rounded-md" />
            <button type="button" class="btn btn-purple hover:bg-bookmark-white hover:text-black">Contact Us</button>
          </div> -->
        </div>
      </div>

      <transition name="fade">
        <div v-if="this.formSubmitted && this.formSuccess" class="flex flex-col gap-3">
          <h1 class="text-white font-semibold text-3xl ">Success</h1>
          <p class="text-white font-light text-base">Thank you. Your message has been sent successfully </p>
        </div>
        <div v-else-if="this.formSubmitted && !this.formSuccess" class="flex flex-col gap-3">
          <h1 class="text-white font-semibold text-3xl ">Message not sent :(</h1>
          <p class="text-white font-light text-base">We are sorry. Please email us
            directly at <a href="mailto:help@epiwatch.com" class="font-bold">help@epiwatch.com</a></p>
        </div>
      </transition>

    </section>



    <!-- <div class="home">
    <h1 class="text-3xl font-bold underline">
      Hello world!
    </h1>
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import ContactForm from './contact/ContactForm.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      formSubmitted: false,
      formSuccess: false,
      baseHeightForm: 0,
    }
  },
  mounted() {
    this.baseHeightForm = document.getElementById('contact').scrollHeight;
    console.log(document.getElementById("contact").scrollHeight)
  },
  computed: {
    formSubmitClass() {
      // function findHeightByID(id) {
      //   let el = document.getElementById('contact')
      //   console.log(el)
      //   return el.scrollHeight
      // }

      if (this.formSubmitted && this.formSuccess) {
        return {
          backgroundColor: "#00e676",
          minHeight: "1rem"
        }
      } else if (this.formSubmitted && !this.formSuccess) {
        return {
          backgroundColor: "#570000",
          minHeight: "1rem"
        }
      } else {
        return {
          backgroundColor: "#072446",
          minHeight: `${this.baseHeightForm}px`
        }
      }
    }
  },
  components: {
    HelloWorld,
    ContactForm
  },
  methods: {
    scrollTo(el) {
      const yOffset = -90;
      const element = document.getElementById(el);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    },
    formSubmitCallback(success) {
      this.formSubmitted = true;
      if (success) {
        this.formSuccess = true;
      }
    },
    FAQToggle(event) {
      const header = event.target.children[0]
      const collapsible = event.target.children[1]
      const chevron = event.target.children[0].children[1]

      const collapsible_height = collapsible.scrollHeight;

      console.log(collapsible.scrollHeight);

      // console.log(event.target.children[0].children[1])

      function removeClass(el, className) {
        el.classList.remove(className)
      }

      function addClass(el, className) {
        el.classList.add(className)
      }


      if (collapsible.classList.contains("faq-clicked")) {
        removeClass(header, 'faq-clicked-header')
        removeClass(collapsible, "faq-clicked")
        collapsible.style.maxHeight = 0 + 'px'
        removeClass(chevron, 'faq-clicked-chevron')

      } else {
        addClass(collapsible, "faq-clicked")
        collapsible.style.maxHeight = collapsible_height + 'px'
        addClass(header, 'faq-clicked-header')
        addClass(chevron, 'faq-clicked-chevron')


      }


      // console.log(chevron);
      // console.log(collapsible.classList)
      // console.log(collapsible);
      // console.log(collapsible.style.backgroundColor);


    }
  }
}
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2000ms
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1
}

.form__submitted {
  max-height: 0;
  overflow: hidden;
}

.faq-clicked-chevron {
  transform: rotate(0deg);
  /* color: #072446; */
}

.faq-clicked-header {
  background-color: white;
  color: #d7431e;
}

.faq-clicked {
  background-color: white;
  /* max-height: 10px; */
}
</style>
